/* You can add global styles to this file, and also import other style files */
*:not(mat-icon) {
	font-family: 'Montserrat', sans-serif !important;
	font-display: swap;
}

/* .form-container iframe {
	height: 500px !important;
} */
.map-container {
	height: 100% !important;
	width: 100% !important;
	position: relative;
}

mark {
	/* padding: 0 !important; */
	/* font-weight: 600; */
	/* background-color: transparent !important; */
	/* background-color: var(--primary-color-4) !important; */
	color: black;
}

#credential_picker_container {
	right: 16px !important;
	top: 55px !important;
}

.backdropBackground {
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(20px);
}

.app-full-bleed-dialog .mat-dialog-container {
	padding: 0;
}

.stripe-error-font {
	color: #df1b41 !important;
	font-size: 0.93rem !important;
	font-weight: normal !important;
	/* text-align: center; */
}

@media screen and (max-width: 768px) {
	.mobile-bottom-transition {
		position: absolute !important;
		bottom: 0 !important;
		max-width: none !important;
	}

	.edit_dialog_scroll_mobile {
		overflow-y: scroll;
	}
}

.dot-yellow,
.dot-blue {
	/* height: 6px;
	width: 6px;
	vertical-align: middle;
	border-radius: 50%;
	display: inline-block; */
	height: 9px;
	width: 9px;
	vertical-align: inherit;
	border: 1px solid white;
	border-radius: 50%;
	display: inline-block;
	box-shadow: 0px 1.125px 2.25px rgb(0 0 0 / 12%);
}

.dot-yellow {
	background-color: #ffbd2e;
}

.dot-blue {
	background-color: #041f60;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.backdropBackground-selected-property {
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(1px);
}

html,
body {
	height: 100%;
	touch-action: manipulation;
	overscroll-behavior: none;
}

.mat-simple-snackbar-action {
	color: #9bcaf8 !important;
}

.Paperform__popupcontent {
	box-shadow: none !important;
}

.Paperform__popupoverlay {
	background-color: white !important;
	transition: none !important;
}

body {
	margin: 0;

	--notification-success: #6acb25;
	--notification-error: #fe2712;
	--notification-warning: #ffbd2e;

	--primary-color-1: #3d98f1;
	--primary-color-2: #6cb1f4;
	--primary-color-3: #9bcaf8;
	--primary-color-4: #cbe3fb;
	--primary-color-5: #fafcff;

	--secondary-color-1: #ffbd2e;
	--secondary-color-2: #ffcd61;
	--secondary-color-3: #ffdd94;
	--secondary-color-4: #ffedc7;
	--secondary-color-5: #fffdfa;

	--grayscale-1: #141a1f;
	--grayscale-2: #29333d;
	--grayscale-3: #3d4d5c;
	--grayscale-4: #52667a;
	--grayscale-5: #668099;
	--grayscale-6: #8599ad;
	--grayscale-7: #a3b3c2;
	--grayscale-8: #c2ccd6;
	--grayscale-9: #e0e6eb;
	--grayscale-10: #f9fafb;

	--size-1: 48px;
	--size-2: 32px;
	--size-3: 20px;
	--size-4: 18px;
	--size-5: 16px;
	--size-6: 14px;

	/* overscroll-behavior-y: none; */
}

.loading-div {
	width: 180px;
	display: flex;
	flex-flow: column;
	align-items: center;
}

.onHoverEffect {
	color: inherit;
	transition: color 0.2s ease 0s, left 0.2s ease 0s;
	/*transition-timing-function: ease-out;*/
	cursor: pointer;
}

.onHoverEffect:hover {
	color: #3c98f1;
}

.footer-icons-shadow:hover {
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.loading {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 0;
}

.loading li {
	height: 0;
	position: absolute;
	top: 50%;
	left: 0;
	width: 0;
	margin: 0;
	height: 10px;
	width: 10px;
	border: 3px solid #3c98f1;
	border-radius: 100%;
	transform: transformZ(0);
	animation: LOADING 2s infinite;
}

.loading li:nth-child(1n) {
	left: -20px;
	animation-delay: 0s;
}

.loading li:nth-child(2n) {
	left: 0;
	animation-delay: 0.2s;
}

.loading li:nth-child(3n) {
	left: 20px;
	animation-delay: 0.4s;
}

.elevation {
	/* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15) !important; */
	cursor: pointer;
}

.elevation:hover {
	/* box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2) !important; */
}

.mat-toolbar-row,
.mat-toolbar-single-row {
	height: 64px;
}

@media (max-width: 599px) {

	.mat-toolbar-row,
	.mat-toolbar-single-row {
		height: 56px;
	}
}

@keyframes LOADING {
	0% {
		transform: scale(0.5);
		background: #3c98f1;
	}

	50% {
		transform: scale(1);
		background: white;
	}

	100% {
		transform: scale(0.5);
		background: #3c98f1;
	}
}

@media screen and (min-width: 1241px) {
	.listing-card-responsiveness {
		max-width: 289.67px;
	}
}

.mat-button.mat-accent[disabled],
.mat-button.mat-primary[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
	opacity: 0.6;
	color: white !important;
}

/* Find it COLORS */
.neutral-dark-color {
	color: #27292b;
}

.neutral-light-color {
	color: #52575c;
}

.secondary-color {
	color: #041f60;
}

.primary-color {
	color: #3d98f1 !important;
}

/* BACKGROUND COLORS */
.primary-background-color,
.bg-primary-color {
	background-color: #3d98f1 !important;
}

/* BUTTONS */
.clear-with-primary-btn {
	color: #3c98f1 !important;
	border: 1px solid #3c98f1 !important;
	outline: none !important;
}

.primary-background-btn {
	background-color: #3c98f1 !important;
	color: white !important;
}

.clean-a-tag {
	text-decoration: none;
	/* color: unset; */
}

.clean-a-tag:hover {
	/* color: unset; */
	text-decoration: none;
}

.tour-label {
	background: #27292b;
	opacity: 0.8;
	backdrop-filter: blur(80px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 4px 0 0 0;
	display: flex;
	position: absolute;
	right: 0;
	top: 99px;
	width: 84px;
	justify-content: center;
	padding: 8px;
}

.tour-label img {
	width: 13px;
	height: 13px;
}

.tour-label h2 {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #ffffff;
	margin-bottom: 0;
}

.price-dot,
.tour-dot {
	position: absolute;
	text-align: center;
	white-space: nowrap;
	pointer-events: auto;
	letter-spacing: 0.5px;
	font-weight: 600;

	border-radius: 4px;
	background-color: #ffffff;
	color: #27292b;
	margin-top: -1px;
	padding: 4px 6px;
	font-size: 10px;
	box-shadow: 0 1px 1px #27292b63;
	top: -25px;
	border: 0.5px solid #e0e0e0;
}

.tour-dot {
	background-color: var(--primary-color-1);
	display: inherit;
	color: white;
	border: none;
	top: -45px;
	font-weight: 500;
	box-shadow: none;
}

.map-dot:hover {
	background-color: var(--notification-success) !important;
}

.map-dot {
	box-shadow: 0 2px 2px #27292b;
	width: 16px;
	height: 16px;
	border-width: 2px;
	border-style: solid;
	border-radius: 50%;
	border-color: white;
	pointer-events: auto;

	position: absolute;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* FIND IT OFFICIAL */
.heading-l,
.heading-m,
.heading-s {
	font-weight: 600;
	font-style: normal;
	color: var(--grayscale-2);
}

.heading-l {
	font-size: var(--size-1);
	line-height: 59px;
	letter-spacing: -2px;
}

.heading-m {
	font-size: var(--size-2);
	line-height: 44px;
	letter-spacing: -1px;
}

.heading-s {
	font-size: var(--size-3);
	line-height: 24px;
}

select,
input,
textarea {
	border: 1px solid var(--grayscale-9);
	border-radius: 4px;
	outline: none;
	font-size: 16px;
	padding: 10px;
	margin-bottom: 16px;
	width: 100%;
	transition: all 150ms ease-in;
	appearance: none;
}

.text-l-sb,
.text-m-sb,
.text-s-sb,
.text-l,
.text-m,
.text-s {
	color: var(--grayscale-1);
	font-style: normal;
	font-weight: 600;
}

.text-l,
.text-m,
.text-s {
	font-weight: 500 !important;
}

.text-l-sb,
.text-l {
	font-size: var(--size-4);
	line-height: 140%;
}

.text-m-sb,
.text-m {
	font-size: var(--size-5);
	line-height: 142%;
}

.text-s-sb,
.text-s {
	font-size: var(--size-6);
	line-height: 144%;
}

.hover-preview {
	background-color: white;
	padding: 6px;
	border-radius: 4px;
	position: absolute;
	z-index: 99;
	border: 1px solid var(--grayscale-9);
	filter: drop-shadow(0px 4px 8px rgba(39, 41, 43, 0.08));
	right: -84px;
	top: 16px;

	display: grid;
	grid-template-columns: 70px 74px;
	gap: 10px;
}

.hover-preview img {
	width: 70px;
	height: 70px;
	border-radius: 3px;
	object-fit: cover;
}